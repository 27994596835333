(function(Modernizr) {
  return Modernizr.addTest('objectfit', !!Modernizr.prefixed('objectFit'), {
    aliases: ['object-fit']
  });
})(Modernizr);

(function($) {
  if (!Modernizr.objectfit) {
    return $('.has-object-fit').each(function() {
      var $container, imgUrl;
      $container = $(this).wrap('<div class="compat-object-fit"></div>');
      imgUrl = $(this).prop('src');
      if (imgUrl) {
        return $(this).parent().css('backgroundImage', 'url(' + imgUrl + ')');
      }
    });
  }
})(jQuery);
