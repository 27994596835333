
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ========================================================================
 */
(function($) {
  var IAB, UTIL;
  IAB = {
    'common': {
      init: function() {
        var ariaMenuCondition, stickyBanner, winHeight;
        ariaMenuCondition = function(context) {
          return !context.hasClass('search-toggle') && !Modernizr.touch && $(window).innerWidth() >= 768;
        };
        $('.nav-with-aria').on('focus.aria  mouseenter.aria', '[aria-haspopup="true"]', function(ev) {
          if (ariaMenuCondition($(this))) {
            return $(ev.currentTarget).attr('aria-expanded', true);
          }
        });
        $('.nav-with-aria').on('blur.aria  mouseleave.aria', '[aria-haspopup="true"]', function(ev) {
          if (ariaMenuCondition($(this))) {
            return $(ev.currentTarget).attr('aria-expanded', false);
          }
        });
        $('.nav-with-aria').on('click ontouchstart', '[aria-haspopup="true"] > a', function(ev) {
          var $parent, $siblings, collapsed;
          if (Modernizr.touch || $(window).innerWidth() < 768) {
            ev.preventDefault();
            $parent = $(ev.currentTarget).parent();
            $siblings = $parent.parent().find('[aria-haspopup="true"]');
            console.log($siblings);
            collapsed = $parent.hasClass('collapsed');
            if ($parent.attr('aria-expanded') === 'true' && !collapsed) {
              $parent.addClass('collapsed');
              return $parent.attr('aria-expanded', false).blur();
            } else {
              $parent.removeClass('collapsed');
              $siblings.attr('aria-expanded', false);
              return $parent.attr('aria-expanded', true);
            }
          }
        });
        $('.menu-search-form').on('click', '.search-toggle', function(e) {
          e.preventDefault();
          if ($(e.currentTarget).attr('aria-expanded') === 'true') {
            $('body').removeClass('m-search-toggle-active');
            return $(e.currentTarget).attr('aria-expanded', false);
          } else {
            $('body').addClass('m-search-toggle-active');
            $(e.currentTarget).attr('aria-expanded', true);
            return $('.search-field').focus();
          }
        });
        $('#menu-toggle').on('click', function(e) {
          var $body, $button, reveal;
          e.preventDefault();
          $button = $(this);
          $body = $('body');
          reveal = $button.attr('aria-controls');
          if ($button.attr('aria-expanded') === 'false') {
            $button.addClass('is-active');
            $body.addClass('menu-active');
            $('[aria-controls=' + reveal + ']').attr('aria-expanded', 'true');
            $('#' + reveal).attr('aria-hidden', 'false');
            $('#' + reveal).trigger('shown');
          } else {
            $button.removeClass('is-active');
            $body.removeClass('menu-active');
            $('[aria-controls=' + reveal + ']').attr('aria-expanded', 'false');
            $('#' + reveal).attr('aria-hidden', 'true');
            $('#' + reveal).trigger('hidden');
          }
        });
        $(window).on('scroll', function() {
          if ($(this).scrollTop() > 0) {
            return $('.banner').addClass('past-top');
          } else {
            return $('.banner').removeClass('past-top');
          }
        });
        winHeight = $(window).innerHeight() * 0.5;
        $('.lightbox-video').fancybox({
          type: 'iframe',
          width: 16 / 9 * winHeight,
          height: winHeight,
          autoSize: false,
          beforeLoad: function() {
            var url;
            url = $(this.element).data("lightbox-url");
            this.href = url;
          },
          padding: 0,
          autoDimensions: false,
          helpers: {
            overlay: {
              locked: false
            }
          }
        });
        $('.responsive-table-unparsed').each(function() {
          var $headers, $rows;
          $headers = $(this).find('thead > tr > th');
          $rows = $(this).find('tbody > tr');
          $rows.each(function() {
            var $cells, index;
            $cells = $(this).find('td');
            index = 0;
            return $cells.each(function() {
              $(this).wrapInner('<div></div>');
              $(this).attr('data-header-text', $headers.eq(index).text());
              return index++;
            });
          });
          $(this).removeClass('responsive-table-unparsed');
          return $(this).addClass('responsive-table-parsed');
        });
        $('.accordion').accordion({
          singleOpen: true,
          transitionSpeed: 400
        });
        $.extend($.scrollTo.defaults, {
          offset: $('.banner').innerHeight() * -1
        });
        stickyBanner = function() {
          return setTimeout(function() {
            var bannerHeight;
            bannerHeight = $('#banner').innerHeight();
            return $('#banner').css('position', 'fixed').next().css('height', bannerHeight);
          }, 100);
        };
        stickyBanner();
        $(window).on('debouncedresize', stickyBanner);
      },
      finalize: function() {
        $(".skip").click(function(e) {
          var skipTo;
          skipTo = "#" + this.href.split('#')[1];
          return $(skipTo).attr('tabindex', -1).on('blur focusout', function() {
            return $(this).removeAttr('tabindex');
          }).focus();
        });
      }
    },
    'page_template_frontpage': {
      init: function() {
        var autoplaySpeed;
        $.stellar({
          hideDistantElements: false,
          horizontalScrolling: false,
          positionProperty: 'transform'
        });
        autoplaySpeed = 6000;
        $('.content-slider').on('init', function(event, slick) {
          return slick.$slides.eq(1).addClass('slick-slide-next');
        });
        $('.content-slider').on('beforeChange edge', function(ev, slick, c, n) {
          var $slide, i, j, len, len1, ref;
          slick.$slides.removeClass('slick-slide-previous slick-slide-next');
          len = slick.$slides.length;
          ref = slick.$slides;
          for (j = 0, len1 = ref.length; j < len1; j++) {
            i = ref[j];
            $slide = $(i);
            if ($slide.index() === n) {
              if ($slide.prev().length) {
                $slide.prev().addClass('slick-slide-previous');
              } else {
                slick.$slides.eq(len - 1).addClass('slick-slide-previous');
              }
              if ($slide.next().length) {
                $slide.next().addClass('slick-slide-next');
              } else {
                slick.$slides.eq(0).addClass('slick-slide-next');
              }
              break;
            }
          }
        });
        $('.content-slider').slick({
          appendDots: '.dots',
          arrows: false,
          asNavFor: '.graphic-slider',
          autoplay: true,
          autoplaySpeed: autoplaySpeed,
          dots: true,
          fade: true,
          pauseOnFocus: false,
          pauseOnHover: false,
          slide: '.slide',
          speed: 2500,
          swipe: false,
          touchMove: false
        });
        $('.graphic-slider').slick({
          arrows: false,
          asNavFor: '.content-slider',
          dots: false,
          fade: true,
          speed: 1000,
          swipe: false,
          touchMove: false
        });
        $('.news-ticker .ticker-items').slick({
          appendArrows: '.news-ticker .ticker-arrows',
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: false,
          fade: true,
          swipe: false,
          touchMove: false
        });
        $('.posts-slider').slick({
          appendArrows: '.posts-slider .arrows',
          adaptiveHeight: true,
          arrows: true,
          dots: false,
          fade: true,
          infinite: false,
          slide: '.post'
        });
        $(window).smartscroll(function() {
          if ($(window).scrollTop() > 10) {
            return $('.scroll-down').fadeOut();
          }
        });
      },
      finalize: function() {}
    },
    'about_us': {
      init: function() {}
    },
    'single_product': {
      init: function() {
        var initProductForm, modal, options;
        initProductForm = function() {
          $("[data-post-company-name]").on("click", function(e) {
            var company;
            e.preventDefault();
            company = $(e.currentTarget).attr('data-post-company-name');
            $('.membership-fields input[name="company_name"]').val(company);
            return $('form.cart').submit();
          });
          $(".single_variation_wrap").on("show_variation", function(event, variation) {
            if (variation.attributes['attribute_pa_membership-level'] === 'member') {
              return $('.membership-fields').show();
            } else {
              return $('.membership-fields').hide();
            }
          });
        };
        initProductForm();
        options = {
          onOpen: function() {
            return initProductForm();
          }
        };
        modal = new VanillaModal["default"](options);
      }
    },
    'page_template_form_page': {
      init: function() {
        $('.datepicker').datepicker({
          dateFormat: "M d, yy"
        });
        $('.input-mask-phone').mask('000-000-0000 ex.00000');
        window.handleCF7Submit = function(cf7) {
          return $.scrollTo('.wpcf7', 800);
        };
      }
    },
    'page_template_contact': {
      init: function() {
        return IAB.page_template_form_page.init();
      }
    },
    'post_type_archive_third_party': {
      init: function() {
        return $('.filter select').on('change', function() {
          return $('#posttype-searchform').submit();
        });
      }
    }
  };
  UTIL = {
    fire: function(func, funcname, args) {
      var fire, namespace;
      fire = void 0;
      namespace = IAB;
      funcname = funcname === void 0 ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      var dbc;
      UTIL.fire('common');
      dbc = document.body.className;
      $.each(dbc.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})(jQuery);

window.initMap = function() {
  var iab_location, infowindow, map, marker;
  localizeJS.latitude = parseFloat(localizeJS.latitude);
  localizeJS.longitude = parseFloat(localizeJS.longitude);
  iab_location = {
    lat: localizeJS.latitude,
    lng: localizeJS.longitude
  };
  map = new google.maps.Map(document.getElementById("map"), {
    center: iab_location,
    scrollwheel: false,
    zoom: 14
  });
  infowindow = new google.maps.InfoWindow({
    content: '<div class="map-info">' + '<a href="' + localizeJS.directions_url + '" target="_blank">' + localizeJS.string.get_directions + '</a></div>'
  });
  marker = new google.maps.Marker({
    map: map,
    position: iab_location,
    title: localizeJS.string.name
  });
  return marker.addListener('click', function() {
    return infowindow.open(map, marker);
  });
};
